import {MetaPageType} from '~/features/shared';

export function meta(args: {
  data?:
    | (MetaPageType & {
        basePage?: undefined;
      })
    | {
        basePage?: MetaPageType;
      };
}) {
  const data = args.data?.basePage
    ? args.data.basePage
    : (args.data as MetaPageType | undefined);

  const seo = data?.seo;
  const og = data?.openGraph;
  const sm = data?.schemaMarkup;

  const arr: any[] = [
    {title: seo?.title},
    {name: 'description', content: seo?.description},
    {
      property: 'og:url',
      content: data?.url,
    },
    {
      property: 'og:title',
      content: og?.title,
    },
    {
      property: 'og:type',
      content: og?.type,
    },
  ];

  if (og?.image) {
    arr.push([
      {
        property: 'og:image',
        content: og?.image.url,
      },
      {
        property: 'og:image:alt',
        content: og?.image.alt,
      },
      {
        property: 'og:image:width',
        content: og?.image.width?.toString(),
      },
      {
        property: 'og:image:height',
        content: og?.image.height?.toString(),
      },
    ]);
  }

  if (og?.description) {
    arr.push({
      property: 'og:description',
      content: og?.description,
    });
  }

  if (sm) {
    arr.push({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'script:ld+json': sm,
    });
  }

  return arr;
}
